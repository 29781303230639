import React, { useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Loadable from "react-loadable"

import Layout from "../components/layout"
import CTABlock from "../components/blocks/cta_block"

import Countdown, { zeroPad } from "react-countdown"

import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const loader = () => (
  <div className="container">
    <div className="row align-items-center">
      <div className="col-6 offset-6">
        loading...
        <Loader type="Puff" color="#ed1940" height={100} width={100} />
      </div>
    </div>
  </div>
)

const EventsCarousel = Loadable({
  loader: () => import("../components/events-slider"),
  loading: loader,
})
const NewsCarousel = Loadable({
  loader: () => import("../components/sections/section_news"),
  loading: loader,
})

const IndexPage = ({ data }) => {
  const page = data.wagtail.page
  const eventSticky = page.eventSticky
  const advert = page.advert
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div id="timer2">
        <b>
          {zeroPad(days, 2)}
          <span>D</span>
        </b>
        <b>
          {zeroPad(hours, 2)}
          <span>H</span>
        </b>
        <b>
          {zeroPad(minutes, 2)}
          <span>M</span>
        </b>
        <b>
          {zeroPad(seconds, 2)}
          <span>S</span>
        </b>
      </div>
    )
  }

  const refBanner = useRef(null)

  useLayoutEffect(() => {
    refBanner.current.style.marginTop = "0px"
  }, [])

  return (
    <div>
      <Layout header={data.wagtail.page.alerts}>
        <section className="hero-area" ref={refBanner}>
          <img src={page.generalPhoto?.src || ""} alt="main image" />
          <div className="header-shadow"></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-xs-12">
                <div className="header-event">
                  {eventSticky && (
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-xs-12">
                      <div className="hero-content">
                        <div className="hero-heading">
                          <h1>{eventSticky?.title}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-xs-12 text-right">
                      <Countdown
                        date={eventSticky.initialdate}
                        renderer={renderer}
                      />
                      <Link
                        className="read-more"
                        to={
                          eventSticky.urlPath
                            ? `/${eventSticky.urlPath.replace(
                                page.urlPath,
                                ""
                              )}`
                            : "/"
                        }
                      >
                        Learn more about the event
                        <img
                          className="arrow-right arrow"
                          src={`../images/arrow-right-2.png`}
                          alt=">"
                        />{" "}
                      </Link>
                    </div>
                  </div>)}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 text-bottom">
                <div className="hero-pragraph">
                  <h2>{page.generalNote}</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {advert && (
          <section className="add-area">
            <div className="add-img">
              <a href={advert.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={advert.image.src}
                  width="728px"
                  height="90px"
                  alt="add"
                />
              </a>
            </div>
          </section>
        )}
        {data.wagtail.page.fullContent.map((item, index) => {
          if (item.field === "Section") {
            if (item.section === "0") {
              // News
              return <NewsCarousel key={index + 20} />
            } else if (item.section === "1") {
              // Events
              return <EventsCarousel key={index + 20} />
            } else {
              // Athletes
              return (
                <>
                  <section className="team-area section-padding">
                    <div className="container">
                      <div className="row">
                        <div className="col-xs-12 col-lg-6 p-right">
                          <div className="team-img">
                            <img
                              src={
                                data.wagtail.page.athleteMonthImage
                                  ? data.wagtail.page.athleteMonthImage.src
                                  : ""
                              }
                              srcSet={
                                data.wagtail.page.athleteMonthImage
                                  ? data.wagtail.page.athleteMonthImage.srcSet
                                  : ""
                              }
                              style={{
                                backgroundColor: data.wagtail.page
                                  .athleteMonthImage
                                  ? "none"
                                  : "#A7A8AA",
                              }}
                              alt="team"
                            />
                          </div>
                        </div>
                        <div className="col-xs-12 col-lg-6 p-left">
                          <div className="team-content">
                            <h2>{data.wagtail.page.athleteMonthTitle}</h2>
                            <h3>{data.wagtail.page.athleteMonthName}</h3>
                          </div>
                          <div className="team-logo">
                            <img
                              src={
                                data.wagtail.page.athleteMonthLogo
                                  ? data.wagtail.page.athleteMonthLogo.src
                                  : ""
                              }
                              style={{
                                backgroundColor: data.wagtail.page
                                  .athleteMonthLogo
                                  ? "none"
                                  : "#A7A8AA",
                              }}
                              alt="brand"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )
            }
          } else if (item.field === "CTA") {
            return <CTABlock data={item} key={index + 20} />
          }
          return ""
        })}
        <section>
          <div style={{ height: "50px" }} />
        </section>
      </Layout>
    </div>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

/* ADEMAS SE HACEN QUERY o SUB QUERYS DENTRO DE LAS PAGINAS */
export const query = graphql`
  {
    wagtail {
      page(id: 3) {
        ... on HomePage {
          urlPath
          generalNote
          generalPhoto {
            ... on ImageObjectType {
              src
            }
          }
          athleteMonthTitle
          athleteMonthName
          athleteMonthImage {
            ... on ImageObjectType {
              src
              width
              height
              srcSet(sizes: 1680)
            }
          }
          athleteMonthLogo {
            ... on ImageObjectType {
              src
              width
              height
            }
          }
          children {
            id
            title
            descendants {
              id
              title
            }
          }
          numchild
          advert {
            id
            url
            image {
              ... on ImageObjectType {
                src
                width
                height
              }
            }
          }
          alerts {
            ... on AlertBlock {
              text
            }
          }
          events {
            id
            ... on EventPage {
              clinicname
              clinicDate
              clubname
              location
              eventname
              eventdescription
              initialdate
              locationname
              locationaddress
              athletetype
              slug
              show
              description
              live
              ancestors(order: "-depth", limit: 1) {
                title
                depth
              }
            }
          }
          eventSticky {
            ... on EventPage {
              title
              initialdate
              urlPath
              ancestors(order: "-depth", limit: 1) {
                title
                depth
              }
              feedImage {
                ... on ImageObjectType {
                  src
                  width
                  height
                }
              }
            }
          }
          fullContent {
            field
            ... on CTABlock {
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
            ... on SectionBlock {
              section
            }
          }
        }
      }
    }
  }
`

export default IndexPage
